import * as React from 'react'
// import ReactFlag from "../reactFlagsComp"
import { useForm } from 'react-hook-form'
import { addForm } from '../../action'
import { siteUrl } from '../_utils'
import { loaderText, telinputContainer } from '../fiveJBR/form.module.css'
import ReactFlag from '../reactFlagsComp-back'
import { ImSpinner } from 'react-icons/im'
import ReactGA from 'react-ga'
import FormComponent from '../formComponent'

const FormLayout = (props) => {

  const [loading, setLoading] = React.useState(false)

  return (<div className={`bg-white`}>

      {/*<div className={'p-6 pt-0 sm:px-5 '}>
        <div className={'my-4 mt-0 text-center'}>
          <button disabled={loading}
                  style={{flex: 1, alignContent: 'center'}}
                  className={'w-10/12 rounded  text-sm  bg-primary-darkGold py-3 px-4 text-white'}
                  type="submit">
            <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>
          </button>
        </div>

        <div className={'my-4 text-center'}>
          <button disabled={loading}
                  style={{flex: 1, alignContent: 'center'}}
                  className={'w-10/12 rounded text-primary-darkGold text-sm border-2 border-primary-darkGold py-3 text-darkGold'}
                  type="submit">
            <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>
            <span>Project Master/Plan Brochure</span>
          </button>
        </div>
      </div>*/}

      <button onClick={() => {
        document.getElementById('downloadbrochure').style.display = 'block'
      }} disabled={loading}
              style={{flex: 1, alignContent: 'center'}}
              className={'w-10/12 rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white mb-8'}
              type="submit">
        <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>

        <span>Download Brochure</span>

      </button>

      <div className={'p-6 border border-primary-gray border-opacity-20'}>
        <div className={'text-l text-center text-sm text-primary-lightGray text-primary-darkGold'}>
          Most awaited <strong>Forest and Waterfront Villa Plots</strong> in Sobha Hartland MBR City are ready for sale
        </div>

        <FormComponent formName={'sobhaHARTLANDPlots2'} mandatoryField={true} countryISO={props.countryISO} buttonText={'Schedule an Appointment'}/>

        <div className={'text-center font-bold text-lg text-primary-gray'}>
          BOOKINGS OPEN!
        </div>
        <div className={'text-center text-sm text-primary-gray'}>
          Book Your Plot with 10% only
        </div>

      </div>

    </div>
  )
}

export default FormLayout
