import {modal} from '../styles/main.module.css'
import * as React from 'react'
import Form from './formComponent'

let showBlock = false


const ModalForm = ({countryISO, formName, downloadType, onClose, brochureType}) => {

    const [pageTitle, setPageTitle] = React.useState('');
    const [btnTitle, setBtnTitle] = React.useState('');
    React.useEffect(() => {
        if (window.location.href.indexOf("downloadbrochure") > -1) {
            showBlock = true
        }
    }, []);
    React.useEffect(() => {
        if (downloadType == 'brochure') {
            setPageTitle('Fill your details to download the brochure')
            setBtnTitle('DOWNLOAD BROCHURE')

        }
        if (downloadType == 'floor') {
            setPageTitle('Fill your details to download the floor plans')
            setBtnTitle('DOWNLOAD FLOOR PLANS')

        }
        if (downloadType == 'payment') {
            setPageTitle('Fill your details to download the payment plans')
            setBtnTitle('DOWNLOAD PAYMENT PLANS')

        }
        if (downloadType == 'interior') {
            setPageTitle('Fill your details to see the Interior Pdf')
            setBtnTitle('SEE INTERIOR DESIGN')

        }
    }, [downloadType])


    return (
        <div id={'downloadbrochure'} className={showBlock ? 'block' : 'hidden'}>
            <div className={modal}>

                <div className={`relative bg-white m-auto block mt-20 sm:mt-10 md:max-w-lg w-11/12 p-2`}>

                    <button
                        onClick={() => {
                            onClose()
                            document.getElementById('downloadbrochure').style.display = 'none'
                        }}
                        className={`z-10 text-right absolute text-3xl top-0 right-1`}>&times;</button>

                    <h3 className={'pt-4 text-center'}>
                        {pageTitle}
                    </h3>

                    <Form

                        downloadType={downloadType}
                        formType={'download'}
                        formName={formName} countryISO={countryISO} buttonText={btnTitle}
                        mandatoryField={true} brochureType={brochureType}/>
                </div>

            </div>

        </div>
    )
}

export default ModalForm



