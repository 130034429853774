// extracted by mini-css-extract-plugin
export var mainSection = "heroArea-module--mainSection--1xznS";
export var headerSection = "heroArea-module--headerSection--2Ftws";
export var topSection = "heroArea-module--topSection--1O_pC";
export var bottomUnderLine = "heroArea-module--bottomUnderLine--2ToIg";
export var footerList = "heroArea-module--footerList--2h1Tv";
export var heroText = "heroArea-module--heroText--3zQDl";
export var sobhaList = "heroArea-module--sobhaList--2BejY";
export var sobhaHeading = "heroArea-module--sobhaHeading--2eqlt";
export var sliderFooterContainer = "heroArea-module--sliderFooterContainer--1ZISr";
export var sliderFooter = "heroArea-module--sliderFooter--2L15W";
export var videoContainer = "heroArea-module--videoContainer--1iQ6R";