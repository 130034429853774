import * as React from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import { sobhaList , videoContainer } from './heroArea.module.css'

const Connectivity = () => {
  return (<>
    <div>

      <div className={videoContainer}>
        <iframe  src="https://www.youtube.com/embed/1kA7V7PE95c" title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </div>




      <div className={'text-center mt-4'}>
        <h2 className={'font-robotoCondensed font-light text-lg pb-0 sm:text-4xl m-auto mb-0 '}>
          Design Your Own Villa at Your Own Land
        </h2>
        <strong>in Sobha Hartland, MBR City</strong>
        <p className={`py-4 md:text-base text-xs text-primary-lightGrayTxt sm:w-2/3 w-full text-left`}>
          Extended development launched by Sobha Group and features 71 Exclusive villa
          plots, within Sobha Hartland in Dubai. Own a fine mix of waterfront & forest villa plots to build a
          unique blend of urban lifestyle with contemporary design.
        </p>
      </div>

      <div className={`container text-left border border-primary-lightBorder mb-4 p-4`}>
        <div>
          <h2
            className={'font-bold md:pb-2 pb-0 text-md font-lato  mb-2'}>Attractive
            Payment Plan 10 - 10 - 80
          </h2>

          <div className={'sm:my-5 text-left text-primary-gray  text-base'}>

            <ul className={`${sobhaList} leading-6`}>

              <li>
                <BsCheckCircle/>
                Pay 10% during Booking
              </li>

              <li>
                <BsCheckCircle/>
                Pay 10% in 3 Months
              </li>
              <li>
                <BsCheckCircle/>
                Pay 80% on Handover
              </li>
            </ul>

          </div>


        </div>

      </div>

    </div>
  </>)
}

export default Connectivity
