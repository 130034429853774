import * as React from 'react'
import FormComponent from '../formComponent'

const FormLayout = (props) => {

  return (<div className={`sm:px-2 md:max-w-sm m-auto`}>


      <div className={'text-l text-center text-sm text-primary-lightGray mt-2 lg:block hidden' }>
        Fastest Selling Plots Ever in Meydan, MBR City<br/>
        <b>Register Your Interest Now</b>
      </div>

      <FormComponent formName={'sobhaHARTLANDPlots2'}  mandatoryField={true} countryISO={props.countryISO} buttonText={'Express your Interest'}/>

      <div className={'text-center font-bold text-lg text-primary-darkGold lg:block hidden'}>
        BOOKINGS OPEN!
      </div>
      <div className={'text-center text-sm text-primary-darkGold lg:block hidden'}>
        Book Your Plot with 10% only
      </div>

    </div>
  )
}

export default FormLayout
