import {
    bottomUnderLine,
    headerSection,
    mainSection,
    heroText,
} from './heroArea.module.css'
import {useStaticQuery, graphql} from 'gatsby'

import FormLayout from './form'
import * as React from 'react'
import {buttonCss, contentDiv, headingText, paraText} from '../../styles/main.module.css'
import {StaticImage} from 'gatsby-plugin-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Slider from 'react-slick'
import image9 from '../../images/aziziBackground.jpg'
import {input} from '../aziziComp/form.module.css'
import {loaderText, telinputContainer} from '../fiveJBR/form.module.css'
import ReactFlag from '../reactFlagsComp-back'
import {ImSpinner} from 'react-icons/im'
import ModalPage from '../modalPage1'

const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
}
const HeroArea = ({countryISO, short, symbol}) => {
    const [loading, setLoading] = React.useState(false)

    return (
        <>
            <div className={mainSection}>
                <section className={`${headerSection} text-center bg-center md:bg-center font-lato grid-cols-12`}>

                    <div className={'block md:hidden'}>
                        <StaticImage
                            width={1900}
                            height={1859}
                            src={'../../images/shobhaLand/slider-villa2-mobile.png'}
                            alt={'Sobha Hartland'}
                        />
                    </div>

                    <div className={'hidden md:block'}>
                        <StaticImage
                            width={1500}
                            height={508}
                            src={'../../images/shobhaLand/slider-villa2-desktop.png'}
                            alt={'Sobha Hartland'}
                        />
                    </div>

                    <div
                        className={'hidden md:block absolute top-0 right-24 grid-cols-4 bg-white p-1 border-b-8 mt-8 border-t-8 border-darkGold'}>
                        <FormLayout countryISO={countryISO}/>
                    </div>

                    <div className={'md:hidden block bg-white'}>
                        <FormLayout countryISO={countryISO} mandatoryitems={true}/>
                        <div className={'my-4 mt-2 text-center px-6'}>
                            <button onClick={() => {
                                document.getElementById('downloadbrochure').style.display = 'block'
                            }} disabled={loading}
                                    style={{flex: 1, alignContent: 'center'}}
                                    className={'w-10/12 rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}
                                    type="submit">
                                <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>

                                <span>Download Brochure</span>

                            </button>
                        </div>
                    </div>

                    <ModalPage formName={'sobhaHARTLANDPlots2'} countryISO={countryISO}/>

                </section>
            </div>
        </>
    )
}

export default HeroArea
